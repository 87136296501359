import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../../framework/src/Message";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showPopover: boolean;
  selectedData: any;
  selectedIndex: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PromocodesAndDiscountController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      showPopover: false,
      selectedData: null,
      selectedIndex: null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start

  handelSubscription = (selectedData: any, selectedIndex: number) => {
    this.setState({ showPopover: true, selectedData, selectedIndex });
  };

  closePopover = () => {
    this.setState({ showPopover: false });
  };

  backToDashboard = () => {
    const messages = new Message(getName(MessageEnum.NavigationMessage));
    messages.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
    messages.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(messages);
  };
  // Customizable Area End
}
