import React from 'react'
import { Avatar, Box, Button, Divider, IconButton, Typography, withStyles } from "@material-ui/core";
import OrderManagementController, { ManageView, ModalType } from "./HospitalBookingManagementController";
import Sidebar from "../../../components/src/Sidebar.web";
import { Calender, filterIcon, noData } from './assets';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomStyledButton from '../../../components/src/CustomStyledButton.web';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'
import CommonModal from '../../../components/src/CommonModal.web';
import AdvanceSearch from '../../dashboard/src/AdvanceSearch.web';
import moment from 'moment';
import { removeStorageData } from '../../../framework/src/Utilities';
import Loader from '../../../components/src/Loader.web';
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";

interface ICardProps {
    bookingId?: string;
    isUpcoming?: boolean;
    isCurrent?: boolean;
    isPast?: boolean;
    isHealthcarePerson?: boolean;
    profile?: string | null;
    bedType?: string;
    cardTitle?: string;
    drName?: string;
    status?: string;
    slotTime?: string;
    slotDay?: string;
}

interface IHealthcarePersonProps {
    isCurrent?: boolean,
    isUpcoming?: boolean,
    isPast?: boolean,
    status?: string;
    slotTime?: string;
    slotDay?: string;
    bookingId?: string;
}

interface IModalMeta {
    modalTitle: React.CSSProperties;
    modalStyle: React.CSSProperties;
    modalHeader: React.CSSProperties;
    title: string;
    onClose: () => void
}



const filterArray = [
    {
        label: 'Past',
        value: 'past'
    },
    {
        label: 'Incoming',
        value: 'incoming'
    },
    {
        label: 'Upcoming',
        value: 'upcoming'
    }
]

export class HospitalBookingManagement extends OrderManagementController {

    async componentWillUnmount() {
        await removeStorageData('booking-list-view')
    }

    renderHealthcarePersonActionBtns = ({ isCurrent = false, isUpcoming = false, isPast = false, slotDay, slotTime, status, bookingId }: IHealthcarePersonProps) => {
        switch (true) {
            case isCurrent:
                return <>
                    <CustomStyledButton disabled={this.state.disableBtnId === bookingId} data-test-id='decline' isSecondary onClick={() => this.handleMedicBookingAction(bookingId, false)} label="Decline" style={webStyle.declineButton} />
                    <CustomStyledButton disabled={this.state.disableBtnId === bookingId} data-test-id='accept' label='Accept' onClick={() => this.handleMedicBookingAction(bookingId, true)} style={webStyle.acceptButton} />
                </>
            case isUpcoming:
                return <Box style={webStyle.upcomingHealthcarePersonWrapper}>
                    <Typography style={webStyle.upcomingTime}>{slotTime}</Typography>
                    <Typography style={webStyle.upcomingDay}>{slotDay}</Typography>
                </Box>
            case isPast:
                return <Box style={webStyle.completedWrapper}>
                    <CheckRoundedIcon />
                    <Typography style={webStyle.completed}>{status}</Typography>
                </Box>
        }
    }

    renderHospitalActionBtn = ({ isCurrent = false, isUpcoming = false, isPast = false, status, bookingId }: IHealthcarePersonProps) => {
        switch (true) {
            case isCurrent:
                return <>
                    <CustomStyledButton disabled={this.state.disableBtnId === bookingId} data-test-id='decline' isSecondary onClick={() => this.handleBedBookingAction(bookingId, false)} label="Decline" style={webStyle.declineButton} />
                    <CustomStyledButton disabled={this.state.disableBtnId === bookingId} data-test-id='accept' label='Accept' onClick={() => this.handleBedBookingAction(bookingId, true)} style={webStyle.acceptButton} />
                </>
            case isUpcoming:
                return <CustomStyledButton data-test-id='view-details' style={webStyle.acceptButton} onClick={() => this.getPatientHealthDetails(bookingId)} label="View Details" />;
            case isPast:
                return <Box style={webStyle.completedWrapper}>
                    <CheckRoundedIcon />
                    <Typography style={webStyle.completed}>{status}</Typography>
                </Box>
        }
    }

    renderCard = (props: ICardProps) => {
        return <Box style={webStyle.cardWrapper}>
            <Box style={webStyle.cardInnerWrapper}>
                <Box style={webStyle.avatarNameWrapper}>
                    <Avatar src={props.profile || props.cardTitle} style={webStyle.cardAvatarStyle} />
                    <Box style={webStyle.cardDataWrapper}>
                        <Typography style={webStyle.cardName} >{props.cardTitle}</Typography>
                        {
                            props.isHealthcarePerson ?
                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography data-test-id='health-details' style={webStyle.healthDetails} onClick={() => this.getMedicBookingDetails(props.bookingId)} >View Details &nbsp; </Typography>
                                    <Typography style={{ ...webStyle.healthDetails, cursor: 'pointer', color: '#ca2424' }}>Dr. {props.drName}</Typography>
                                </Box>
                                :
                                <>
                                    <Typography style={webStyle.bedWrapper}> {props.bedType} </Typography>
                                    {props.isCurrent && <Typography style={webStyle.viewDetails} data-test-id='view-details-link' onClick={() => this.getPatientHealthDetails(props.bookingId)} >View Details</Typography>}
                                </>
                        }
                    </Box>
                </Box>
                <Box style={webStyle.actionButtonWrapper}>
                    {
                        props.isHealthcarePerson ?
                            this.renderHealthcarePersonActionBtns({ isCurrent: props.isCurrent, isUpcoming: props.isUpcoming, isPast: props.isPast, slotTime: props.slotTime, slotDay: props.slotDay, status: props.status, bookingId: props.bookingId }) :
                            this.renderHospitalActionBtn({ isCurrent: props.isCurrent, isUpcoming: props.isUpcoming, isPast: props.isPast, status: props.status, bookingId: props.bookingId })
                    }
                </Box>
            </Box>
            <Divider style={webStyle.cardDivider} />
        </Box>
    }

    renderHealthParameterList = (array: { name: string }[]) => {
        return array.length > 0 ? array.map((disease, index) => <Box>{index + 1}. {disease.name}</Box>) : "No"
    }

    renderYesNo = (conditionalElement: boolean) => {
        return conditionalElement ? "Yes" : "No"
    }

    renderModalContent = (modaType: string) => {
        const { classes } = this.props;
        switch (modaType) {
            case "patient-details":
                const patientDetails = [
                    {
                        key: 'Name',
                        value: this.state.patientDetails?.attributes.patient_name
                    },
                    {
                        key: 'Age',
                        value: `${this.state.patientDetails?.attributes.patient_age} y/o`
                    },
                    {
                        key: 'Gender',
                        value: this.state.patientDetails?.attributes.patient_gender
                    },
                    {
                        key: 'Bed Type',
                        value: this.state.patientDetails?.attributes.bed_type

                    },
                    {
                        key: "Bed Booked",
                        value: this.state.patientDetails?.attributes.bed_name
                    },
                    {
                        key: 'Date',
                        value: moment(this.state.patientDetails?.attributes.date).format("Do MMM'YY")
                    }
                ]
                return <Box style={webStyle.patientDetailsModalWrapper}>
                    <Avatar src={this.state.patientDetails?.attributes.patient_profile_image} style={webStyle.modalAvatar} />
                    <Box style={webStyle.patientDetailsKeyValueWrapper}>
                        {patientDetails.map((details) => <Box style={webStyle.patientDetailsWrapper}>
                            <Typography style={webStyle.patientKey}>{details.key}</Typography>
                            <Typography className={classes.patientValue}>{details.value}</Typography>
                        </Box>)}
                    </Box>
                    <Box style={webStyle.healthInsuranceLinks} >
                        <Typography style={webStyle.modalLinks} data-test-id='health-details-link' onClick={() => this.getHealthDetails(this.state.patientDetails?.id)}>Health Details</Typography>
                        <span style={webStyle.modalLinks}>|</span>
                        <Typography style={webStyle.modalLinks} data-test-id='insurance-link' onClick={() => this.getInsuranceList(this.state.patientDetails?.id)}>View Insurance</Typography>
                    </Box>
                </Box>
            case "medic-booking-details":
                const medicBookingAttributes = this.state.medicBookingDetails?.booking.data.attributes;
                const medicBookingDetails = [
                    {
                        key: 'Name',
                        value: medicBookingAttributes?.patient_name
                    },
                    {
                        key: 'Age',
                        value: `${medicBookingAttributes?.patient_age} y/o`
                    },
                    {
                        key: 'Gender',
                        value: medicBookingAttributes?.patient_gender
                    },
                    {
                        key: 'Appointment with',
                        value: `+91 ${medicBookingAttributes?.healthcare_personnel_number}`

                    },
                    {
                        key: "Department",
                        value: medicBookingAttributes?.healthcare_personnel_department
                    },
                    {
                        key: 'Date',
                        value: moment(medicBookingAttributes?.date).format("Do MMM'YY")
                    },
                    {
                        key: 'Time Slot',
                        value: medicBookingAttributes?.slot_time
                    },
                    {
                        key: 'Problem',
                        value: medicBookingAttributes?.problem
                    }
                ]
                return <Box style={webStyle.patientDetailsModalWrapper}>
                    <Avatar src={medicBookingAttributes?.patient_image} style={webStyle.modalAvatar} />
                    <Box style={webStyle.patientDetailsKeyValueWrapper}>
                        {medicBookingDetails.map((details) => <Box style={webStyle.patientDetailsWrapper}>
                            <Typography style={webStyle.patientKey}>{details.key}</Typography>
                            <Typography className={classes.patientValue}>{details.value}</Typography>
                        </Box>)}
                    </Box>
                    <Box style={webStyle.healthInsuranceLinks} >
                        <Typography style={{ ...webStyle.modalLinks, width: '100%', textAlign: 'center' }} data-test-id='medic-health-details-link' onClick={() => this.getMedicBookingHealthDetails(this.state.medicBookingDetails?.booking.data.id)}>Health Details</Typography>
                    </Box>
                </Box>
            case "health-details":
                const healthDetailsAttributes = this.state.healthDetails?.data.attributes!;
                const healthDetails = [
                    {
                        label1: 'Disease Name',
                        label2: 'MM / YYYY',
                        key: healthDetailsAttributes?.diseases.length > 0 ? healthDetailsAttributes?.diseases.map((disease, index) => <Box>{index + 1}. {disease.disease_name}</Box>) : "No",
                        value: healthDetailsAttributes?.diseases?.length > 0 ? healthDetailsAttributes?.diseases.map((disease, index) => <Box>{index + 1}. {this.getMonth(disease.disease_months)} / {disease.disease_years}</Box>) : "No"
                    },
                    {
                        label1: 'Height',
                        label2: 'Weight',
                        key: `${healthDetailsAttributes?.patient_info.height ?? "0"} cm`,
                        value: `${healthDetailsAttributes?.patient_info.weight ?? "0"} Kg`,
                    },
                    {
                        label1: 'Medicine',
                        label2: 'Previous Surgery',
                        key: this.renderHealthParameterList(healthDetailsAttributes?.medicines),
                        value: this.renderHealthParameterList(healthDetailsAttributes?.surgeries)
                    },
                    {
                        label1: 'Allergies',
                        label2: 'Previous Hospital',
                        key: this.renderHealthParameterList(healthDetailsAttributes?.allergies),
                        value: this.renderYesNo(healthDetailsAttributes?.patient_info.previous_hospital)
                    },
                    {
                        label1: 'Smoke',
                        label2: 'Drink',
                        key: this.renderYesNo(healthDetailsAttributes?.patient_info.smoke),
                        value: this.renderYesNo(healthDetailsAttributes?.patient_info.drink)
                    },
                    {
                        label1: 'Prescription',
                        label2: 'Reports',
                        key: healthDetailsAttributes?.prescriptions.length > 0 ? healthDetailsAttributes?.prescriptions.map((prescription, index) => <Box onClick={() => this.openLink(prescription.url)}>{index + 1}. <span style={{ color: '#3780E0' }}> Prescription </span></Box>) : "Not Available",
                        value: healthDetailsAttributes?.reports.length > 0 ? healthDetailsAttributes?.reports.map((report, index) => <Box onClick={() => this.openLink(report.url)}>{index + 1}. <span style={{ color: '#3780E0' }}> Report </span></Box>) : "Not Available",
                    }
                ]
                return healthDetails.map((detail) => <Box style={{ marginTop: '24px', }}>
                    <Box style={webStyle.healthDetailsLabelWrapper}>
                        <Typography style={webStyle.healthDetailLabels}>{detail.label1}</Typography>
                        <Typography style={webStyle.healthDetailLabels}>{detail.label2}</Typography>
                    </Box>
                    <Box style={webStyle.healthDetailsWrapper}>
                        <Typography className={classes.healthDetailsKey}>{detail.key}</Typography>
                        <Typography className={classes.healthDetailsValue}>{detail.value}</Typography>
                    </Box>
                </Box>)
            case "insurance-modal": return <Box>
                <Typography style={webStyle.insuranceHeader}>Selected Insurance</Typography>
                {
                    this.state.insuranceList.map((insurance) => (
                        <>
                            <Typography style={webStyle.insurance}>{insurance.name}</Typography>
                            <Divider style={webStyle.insuranceDivider} />
                        </>
                    ))
                }
            </Box>
        }
    }

    renderModalMeta = (modalType: string): IModalMeta => {
        switch (modalType) {
            case ModalType.PATIENT_DETAILS:
            case ModalType.MEDIC_BOOKING_DETAILS:
                return {
                    modalTitle: webStyle.patientDetailsModalTitle,
                    modalStyle: webStyle.patientmodalStyle,
                    modalHeader: webStyle.patientModalTitleWrapper,
                    title: 'View Details',
                    onClose: this.handleClosePatientDetailsModal
                };
            case ModalType.HEALTH_DETAILS: return {
                modalTitle: webStyle.HealthDetailsModalTitle,
                modalStyle: webStyle.healthDetailsModalStyle,
                modalHeader: webStyle.healthDetailsModalTitleWrapper,
                title: 'Health Details',
                onClose: this.handleCloseHealthDetailsModal
            };
            case ModalType.INSURANCE_MODAL: return {
                modalTitle: webStyle.insuranceModalTitle,
                modalStyle: webStyle.insuranceModalStyle,
                modalHeader: webStyle.insuranceModalTitleWrapper,
                title: 'View Insurance',
                onClose: this.handleCloseInsuranceModal
            }
            default: return {
                modalTitle: webStyle.patientDetailsModalTitle,
                modalStyle: webStyle.patientmodalStyle,
                modalHeader: webStyle.patientModalTitleWrapper,
                title: 'View Details',
                onClose: this.handleClosePatientDetailsModal
            }
        }
    }

    noDataFound = () => {
        return !this.state.isListLoading && <Box style={webStyle.noDataFoundWrapper}>
            <img src={noData} style={webStyle.noDataIcon} alt="" />
            <Box style={webStyle.noDataText}>Not Available</Box>
        </Box>
    }

    render() {
        const { classes } = this.props;
        return <Box className={classes.container}>
            <SidebarNotificationWrapper {...this.props}>
                <Box className={classes.rightWrapper}>
                    <Box style={webStyle.pageContentWrapper}>
                        <AdvanceSearch {...this.props} />
                        <Box className={classes.bookingsFilterWrapper}>
                            <Typography style={webStyle.bookings}>Bookings</Typography>
                            <IconButton data-test-id='filter' style={webStyle.filterIcon} onClick={this.handleOpenFilter}><img src={filterIcon} alt="filter_icon" /></IconButton>
                            {this.state.filterPopoverEl && <>
                                <div style={webStyle.overlay} data-test-id='overlay' onClick={this.handleCloseFilter}></div>
                                <Box style={{ ...webStyle.filterModalWrapper, top: `${this.state.filterPopoverEl?.getBoundingClientRect() && this.state.filterPopoverEl?.getBoundingClientRect().bottom + window.scrollY - 120}px` }}>
                                    <Box>
                                        {filterArray.map((filter) => <Box data-test-id='filter-item' onClick={() => this.handleChangeFilter(filter.value)} style={{ ...webStyle.filterButtonWrapper, backgroundColor: this.state.filterMode === filter.value ? 'rgba(55, 128, 224, 0.5)' : 'transparent', color: this.state.filterMode === filter.value ? '' : '#808080',cursor:"pointer" }}>
                                            {filter.label}
                                        </Box>)}
                                        <Box style={{ ...webStyle.filterButtonWrapper, height: 'auto' }}>
                                            <Button data-test-id='select-filter' onClick={this.handleSelectFilter} style={webStyle.filterListBtnCommonStyle}>Select</Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </>}
                        </Box>
                        <Box className={classes.scheduleFieldContainer}>
                            <Typography variant="h4" className={classes.scheduleDateLabel}>Select Date</Typography>
                            <Box className={classes.customSCName}>
                                <span className={classes.customDateInputName}>
                                    <DatePicker
                                        monthsShown={2}
                                        popperClassName={classes.popperScheduleClassName}
                                        wrapperClassName={classes.datepickerScheduleClassFullWidth}
                                        calendarClassName={classes.datepickerNurseClass}
                                        formatWeekDay={nameOfDay => nameOfDay.slice(0, 1)}
                                        onChange={this.handleDateChange}
                                        openToDate={this.state.selectedDate}
                                        className={classes.dateField}
                                        value={this.formatCustomDate(this.state.selectedDate)}
                                        dateFormat="Select date"
                                        placeholderText="Select date"
                                        minDate={new Date()}
                                        shouldCloseOnSelect={false}
                                        open={true}
                                        data-test-id="select-date"
                                    />
                                </span>
                            </Box>
                            <Box className={classes.selectBox}>Select Date</Box>
                        </Box>

                        <Box className={classes.tabsWrapper}>
                            <Button data-test-id='bed-view' onClick={this.handleOpenBedView} className={this.state.manageView === ManageView.BED ? classes.activeTab : classes.inActiveTab}>
                                Beds
                            </Button>
                            <Button data-test-id='healthcare-view' onClick={this.handleOpenHealthCarePersonView} className={this.state.manageView === ManageView.HEALTHCARE_PERSON ? classes.activeTab : classes.inActiveTab}>
                                Healthcare Personnel
                            </Button>
                        </Box>

                        <Box style={{ marginTop: '24px' }}>
                            <Typography style={webStyle.insuranceTitle}>{this.state.selectedFilter} Bookings</Typography>
                            <div data-test-id='scroll-div' style={{ height: '345px', overflow: 'auto', scrollbarColor: "#d9d5d5 white", scrollbarWidth: "thin" as "thin", }} ref={this.scrollContainerRef} onScroll={this.onScroll}>
                                {this.state.manageView === ManageView.BED ? <>
                                    {this.state.bedBookingList.length > 0 ? this.state.bedBookingList.map((bedData) => {
                                        return this.renderCard({
                                            bookingId: bedData.id,
                                            isCurrent: this.state.selectedFilter === "incoming",
                                            isUpcoming: this.state.selectedFilter === 'upcoming',
                                            isPast: this.state.selectedFilter === 'past',
                                            cardTitle: bedData.attributes.patient_name,
                                            profile: bedData.attributes.patient_profile_image,
                                            bedType: bedData.attributes.bed_type,
                                            status: bedData.attributes.status
                                        })
                                    }) : this.noDataFound()}
                                </> :
                                    <>
                                        {this.state.healthcarePersonBookingList.length > 0 ? this.state.healthcarePersonBookingList.map((medicBookingData) => {
                                            return this.renderCard({
                                                isHealthcarePerson: true,
                                                bookingId: medicBookingData.id,
                                                isCurrent: this.state.selectedFilter === "incoming",
                                                isUpcoming: this.state.selectedFilter === 'upcoming',
                                                isPast: this.state.selectedFilter === 'past',
                                                cardTitle: medicBookingData.attributes.patient_name,
                                                profile: medicBookingData.attributes.patient_profile_image,
                                                drName: medicBookingData.attributes.doctor_name,
                                                slotDay: medicBookingData.attributes.day,
                                                slotTime: medicBookingData.attributes.slot_time,
                                                status: medicBookingData.attributes.status
                                            })
                                        }) : this.noDataFound()}
                                    </>
                                }
                                {this.state.isListLoading && <Box style={webStyle.loadingText}>Loading...</Box>}
                            </div>
                        </Box>
                    </Box>
                </Box>
            </SidebarNotificationWrapper>

            <CommonModal
                data-test-id='modal'
                open={this.state.modalOpen}
                closeIcon
                dialogStyle={this.renderModalMeta(this.state.modalType).modalStyle}
                titleBoxStyle={this.renderModalMeta(this.state.modalType).modalHeader}
                titleStyle={this.renderModalMeta(this.state.modalType).modalTitle}
                modalTitle={this.renderModalMeta(this.state.modalType).title}
                onClose={this.renderModalMeta(this.state.modalType).onClose}
                contentBoxStyle={{ paddingBottom: '32px' }}
            >
                {this.renderModalContent(this.state.modalType)}
            </CommonModal>
            <Loader loading={this.state.isLoading} />
        </Box >
    }
}

const webStyle = {
    noDataFoundWrapper: {
        width: "100%",
        display: "block",
        margin: "50px auto"
    },
    noDataIcon: {
        display: "flex",
        margin: "auto",
        height: '90px',
        width: '90px'
    },
    noDataText: {
        fontSize: "20px",
        fontFamily: "SF Pro Text Medium",
        color: "#808080",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        margin: "20px auto"
    },
    loadingText: {
        fontSize: "20px",
        fontFamily: "SF Pro Text Medium",
        fontWeight: 500,
        color: "#808080",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: "10px",
        maxWidth: '752px'
    },
    patientDetailsKeyValueWrapper: {
        marginTop: '18px',
        width: '100%'
    },
    filterIcon: {
        position: 'absolute',
        zIndex: 200,
        backgroundColor: 'white',
        borderRadius: '8px',
        width: '40px',
        height: '40px',
        right: 0
    } as React.CSSProperties,
    filterModalWrapper: {
        position: "absolute",
        zIndex: 200,
        right: 0,
        backgroundColor: 'white',
        borderRadius: '10px',
        overflow: 'hidden',
        width: '343px'
    } as React.CSSProperties,
    filterListBtnCommonStyle: {
        width: '100%',
        height: '56px',
        textTransform: 'capitalize',
        fontSize: '18px',
        borderRadius: '8px',
        fontFamily: 'SF Pro Text Bold',
        backgroundColor: '#014866',
        color: 'white'
    } as React.CSSProperties,
    filterButtonWrapper: {
        height: "70px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '15px',
        boxSizing: 'border-box',
        fontFamily: 'SF Pro Text Medium',
        textTransform: 'capitalize',
        fontSize: '18px',
    } as React.CSSProperties,
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        width: 'calc(100% - 132px)',
        zIndex: 100
    } as React.CSSProperties,
    patientDetailsModalTitle: {
        textAlign: 'center',
        width: '100%',
        color: '#292929',
        fontSize: '18px',
        fontFamily: 'SF Pro Text Bold'
    } as React.CSSProperties,
    patientmodalStyle: {
        borderRadius: '12px',
        width: '420px'
    },
    patientModalTitleWrapper: {
        paddingTop: '34px'
    },
    HealthDetailsModalTitle: {
        color: '#292929',
        fontSize: '28px',
        fontFamily: 'SF Pro Text Bold',
        lineHeight: '33px'
    },
    healthDetailsModalStyle: {
        borderRadius: '25px',
        width: '480px'
    },
    healthDetailsModalTitleWrapper: {
        paddingTop: '41px'
    },
    insuranceModalTitle: {
        textAlign: 'center',
        width: '100%',
        color: '#292929',
        fontSize: '20px',
        fontFamily: 'SF Pro Text Bold',
    } as React.CSSProperties,
    insuranceModalStyle: {
        width: '390px',
        borderRadius: '25px'
    },
    insuranceModalTitleWrapper: {
        paddingTop: '34px'
    },
    declineButton: {
        width: '164px',
        fontSize: '18px'
    },
    acceptButton: {
        width: '164px'
    },
    upcomingHealthcarePersonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px'
    } as React.CSSProperties,
    upcomingTime: {
        color: '#808080',
        fontFamily: 'SF Pro Text Medium',
        fontSize: '16px',
        lineHeight: '19.09px'
    },
    upcomingDay: {
        color: '#808080',
        fontFamily: 'SF Pro Text Medium',
        fontSize: '16px',
        lineHeight: '19.09px',
        textAlign: 'right'
    } as React.CSSProperties,
    completedWrapper: {
        color: '#30D158',
        display: 'flex',
        alignItems: 'center',
        gap: '12px'
    },
    completed: {
        fontFamily: 'SF Pro Text Bold',
        textTransform: 'capitalize'
    } as React.CSSProperties,
    cardWrapper: {
        marginTop: "24px",
        maxWidth: '752px'
    },
    cardInnerWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    avatarNameWrapper: {
        display: 'flex',
        alignItems: "center"
    },
    cardAvatarStyle: {
        width: '70px',
        height: '70px',
        marginRight: '20px'
    },
    cardDataWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '6px'
    } as React.CSSProperties,
    cardName: {
        color: "black",
        fontFamily: 'SF Pro Text Medium',
        fontSize: '18px',
        lineHeight: '21.48px'
    },
    healthDetails: {
        color: '#3780E0',
        fontFamily: 'SF Pro Text Regular',
        fontSize: "16px",
        cursor: 'pointer'
    },
    bedWrapper: {
        color: '#014866',
        fontFamily: 'SF Pro Text Regular',
        fontSize: '16px'
    },
    speciality: {
        color: '#AC8E68'
    },
    viewDetails: {
        color: '#3780E0',
        fontFamily: 'SF Pro Text Bold',
        fontSize: "14px",
        cursor: 'pointer'
    },
    actionButtonWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '24px',
        fontSize: '18px',
        fontFamily: 'SF Pro Text Medium',
        fontWeight: 500
    },
    cardDivider: {
        backgroundColor: '#EFEFEF',
        marginTop: '20px'
    },
    patientDetailsModalWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    } as React.CSSProperties,
    patientDetailsWrapper: {
        marginTop: '14px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    patientKey: {
        color: '#292929',
        fontFamily: 'SF Pro Text Medium',
        fontSize: '18px',
        width: '100%'
    },
    healthInsuranceLinks: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '275px',
        marginTop: '44px',
    },
    modalLinks: {
        color: '#3780E0',
        cursor: 'pointer',
        fontFamily: 'SF Pro Text Medium',
        fontSize: '16px',
    },
    modalAvatar: {
        width: '100px',
        height: '100px'
    },
    healthDetailsLabelWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '14px'
    },
    healthDetailLabels: {
        color: '#808080',
        fontFamily: 'SF Pro Text Medium',
        fontSize: '16px',
        width: '100%'
    },
    healthDetailsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    insuranceHeader: {
        color: '#808080',
        fontFamily: 'SF Pro Text Regular',
        fontSize: '16px',
        marginBottom: '20px'
    },
    insurance: {
        color: '#292929',
        fontSize: '16px',
        fontFamily: 'SF Pro Text Medium',
    },
    insuranceDivider: {
        backgroundColor: '#F0F0F0',
        margin: '10px 0 14px 0'
    },
    pageContentWrapper: {
        padding: '46px 60px'
    },
    bookings: {
        color: '#292929',
        fontFamily: 'SF Pro Text Bold',
        fontSize: '28px',
        lineHeight: '33.41px'
    },
    insuranceTitle: {
        color: '#292929',
        fontFamily: "SF Pro Text Bold",
        fontSize: '20px',
        lineHeight: '23.87px',
        textTransform: 'capitalize',
        marginBottom: '20px'
    } as React.CSSProperties
}

const styles = {
    patientValue: {
        color: '#808080',
        fontFamily: 'SF Pro Text Regular',
        fontSize: '18px',
        lineHeight: '22px',
        width: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap' as const,
        '&:hover': {
            overflow: 'auto',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
        }
    },
    healthDetailsKey: {
        color: '#292929',
        fontFamily: 'SF Pro Text Medium',
        fontSize: '18px',
        cursor: 'pointer',
        width: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap' as const,
        '&:hover': {
            overflow: 'auto',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
        }
    },
    healthDetailsValue: {
        color: '#292929',
        fontFamily: 'SF Pro Text Medium',
        fontSize: '18px',
        width: '100%',
        cursor: 'pointer',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap' as const,
        '&:hover': {
            overflow: 'auto',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
        }
    },
    container: {
        height: "100%",
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: "100vh",
        overflowX: "hidden" as const
    },
    rightWrapper: {
        minHeight: '100vh',
        width: '100%'
    },
    sidebarWrapper: {
        width: '132px',
        minHeight: 'max(100vh, 700px)'
    },
    searchIcons: {
        width: "24px",
        objectFit: "cover" as const,
        height: "24px",
        display: "flex",
        justifyContent: "center",
        margin: "0px auto"
    },
    notificationWrapper: {
        minWidth: '340px'
    },
    popperScheduleClassName: {
        "& .react-datepicker": {
            fontFamily: "SF Pro Text Regular !important",
            fontSize: "18px !important",
            marginTop: "60px !important",
            borderBottom: "1px solid #aca8a840 !important",
            borderRight: "1px solid #aca8a840 !important",
            borderLeft: "1px solid #aca8a840 !important",
            borderTop: "1px solid transparent !important",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px"
        }
    },
    datepickerNurseClass: {
        "& .react-datepicker__day-name": {
            padding: "11px",
            fontSize: "16px",
            color: "#808080",
            fontFamily: "SF Pro Text Medium",
            width: "25px"
        },
        "& .react-datepicker__day--selected": {
            border: "none !important",
            color: "#ffffff !important",
            backgroundColor: "#3780E0",
            width: "25px",
        },
        "& .react-datepicker__day--outside-month": {
            backgroundColor: "transparent !important",
        },
        "& .react-datepicker__day": {
            padding: "11px !important",
            fontSize: "16px",
            fontFamily: "SF Pro Text Medium",
            color: "#292929",
            width: "25px",
        },
        "& .react-datepicker__day--disabled": {
            color: "#808080 !important",
            width: "25px",
        },
        "& .react-datetime-picker__wrapper": {
            border: "none"
        },
        "& .react-datepickerClass-wrapper": {
            display: "flex !important",
        },
        "& .react-datepickerClass__month": {
            margin: "0px !important"
        },
        "& .react-datepickerClass-popper": {
            transform: "translate(90px, 209px) !important"
        },
        "& .react-datepickerClass__day": {
            width: "1.3rem !important",
            height: "1rem !important"
        },
        "& .react-datepickerClass__day-name": {
            width: "1.3rem !important",
            height: "1rem !important"
        },
        "& .react-datepickerClass__month-container": {
            margin: "0px 0px 5px !important"
        },
        "& .react-datepickerClass__day-names": {
            marginBottom: "-1px !important",
            caretColor: "transparent"
        },
        "& .react-datepickerClass__navigation": {
            height: "22px !important",
            width: "22px !important",
            margin: "5px -2px !important",
            caretColor: "transparent"
        },
        "& .react-datepickerClass__day--keyboard-selected": {
            color: "#000 !important",
            backgroundColor: "white !important",
            caretColor: "transparent"
        },
        "& .react-datepickerClass__day--today": {
            color: "#1d5d90 !important",
            backgroundColor: "white !important",
            fontWeight: "bold !important",
            caretColor: "transparent"
        },
        "& .react-datepickerClass__day:hover": {
            color: "#ccc !important",
            backgroundColor: "white !important",
            caretColor: "transparent"
        },
        "& .react-datepicker__triangle": {
            display: "none !important"
        },
        "& .react-datepicker__navigation--next": {
            right: "30px",
            backgroundColor: "#292929",
            width: "28px",
            height: "28px",
            borderRadius: "6px",
            top: "30px"
        },
        "& .react-datepicker__navigation--previous": {
            left: "30px",
            backgroundColor: "#292929",
            width: "28px",
            height: "28px",
            borderRadius: "6px",
            top: "30px"
        },
        "& .react-datepicker__navigation-icon::before": {
            borderColor: "#fff !important",
            borderRadius: "2px !important",
            top: "7px !important",
            borderWidth: "2px 2px 0 0"
        },
        "& .react-datepicker__navigation-icon::after": {
            borderColor: "#fff !important",
            borderRadius: "2px !important",
            top: "7px !important",
            borderWidth: "2px 2px 0 0"
        },
        "& .react-datepicker__month-container": {
            padding: "10px"
        },
        "& .react-datepicker__header": {
            backgroundColor: "#fff !important",
            border: "none !important"
        },
        "& .react-datepicker__current-month": {
            margin: "15px auto",
            fontSize: "16px",
            fontFamily: "SF Pro Text Medium",
            color: "#292929"
        },
        "& .react-datepicker__day--keyboard-selected": {
            border: "none !important",
            color: "#ffffff !important",
            backgroundColor: "#3780E0",
            width: "25px",
        },
        "& .react-datepicker__day:hover": {
            width: "25px",
        },
        "& .react-datepicker__day.react-datepicker__day--030.react-datepicker__day--selected.react-datepicker__day--outside-month": {
            backgroundColor: "transparent !important",
            "&:focus": {
                backgroundColor: "transparent !important",
            },
        }
    },
    datepickerScheduleClassFullWidth: {
        width: "100%",
        color: "#292929",
        border: "none",
    },
    customDateInputName: {
        width: "100%",
        borderRadius: "8px",
        "& input": {
            color: "#292929",
            fontSize: "18px",
            background: `url(${Calender}) left / contain no-repeat`,
            backgroundPosition: "12px",
            backgroundSize: "25px",
            width: "382px",
            height: "58px",
            border: "1px solid #808080",
            display: "flex",
            borderRadius: "8px",
            fontFamily: "SF Pro Text Regular",
            caretColor: "transparent"
        },
        "& ::-webkit-input-placeholder": {
            color: "#808080",
        },
        "&:focus": {
            border: "1px solid #292929",
        },
    },
    scheduleDateLabel: {
        fontSize: "18px",
        fontFamily: "SF Pro Text Regular",
        margin: "0px 0px 15px",
        textAlign: "left" as "left",
        caretColor: "transparent",
        color: "#292929"
    },
    scheduleFieldContainer: {
        width: "94%",
        margin: "15px 0px 20px"
    },
    dateField: {
        "& .react-datepicker-ignore-onclickoutside": {
            border: "none !important",
            borderColor: "transparent !important"
        },
        padding: "15px 0px 15px 60px",
        display: "flex",
        border: "1px solid #808080",
        justifyContent: "center",
        cursor: "pointer",
        borderRadius: "8px",
        "&:focus-visible": {
            outline: "none",
            border: "2px solid #292929",
        },
        "&:focus": {
            outline: "none",
            border: "2px solid #292929",
        },
        "&:hover": {
            outline: "none",
            border: "1px solid #292929",
        },
        ".react-datepicker__input-container input::placeholder": {
            fontSize: "18px",
            opacity: 1,
            color: "#808080",
        },
        fontSize: "18px",
        color: "#292929",
        "& input": {
            color: "#292929",
        }
    },
    tabsWrapper: {
        display: 'flex',
        padding: '11px 20px',
        alignItems: 'center',
        boxSizing: 'border-box' as const,
        maxWidth: '350px',
        width: '100%',
        border: '1px solid #808080',
        borderRadius: '8px',
        justifyContent: 'space-between',
        marginTop: '460px'
    },
    activeTab: {
        textTransform: 'capitalize' as const,
        backgroundColor: '#3780E0',
        color: 'white',
        borderRadius: '8px',
        fontSize: '18px',
        padding: "10px 15px",
        lineHeight: '22px',
        fontFamily: 'SF Pro Text Semibold',
        "&:hover": {
            backgroundColor: '#3780E0',
        }
    },
    inActiveTab: {
        color: '#808080',
        fontFamily: 'SF Pro Text Medium',
        padding: '10px 15px',
        fontSize: '18px',
        lineHeight: '22px',
        textTransform: 'capitalize' as const
    },
    bookingsFilterWrapper: {
        position: 'relative' as const,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '40px',
        marginBottom: '34px'
    },
    selectBox: {
        height: "55px",
        backgroundColor: "rgb(235, 249, 255)",
        fontSize: "18px",
        fontFamily: "Sf Pro Text Semibold",
        width: "120%",
        maxWidth: "774px",
        margin: "15px 0px 0px",
        alignItems: "center" as "center",
        display: "flex",
        paddingLeft: "26px",
        color: "#014866",
        borderTopLeft: "1px solid #aca8a840 !important",
        borderTopRight: "1px solid #aca8a840 !important",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
    },
}

export default withStyles(styles)(HospitalBookingManagement);