import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { DashboardDataType } from "./types";
import React from "react";


interface DoctorListResponse {
  healthcare_personnels: {
    data: HealthcarePersonnel[];
  };
  meta: {
    total_count: number;
  };
}

interface HealthcarePersonnelListResponse{
  search_results: {
    data: HealthcarePersonnelUser[];
  },
  meta: {
    total_count: number;
  };
}

interface HealthcarePersonnel {
  id: string;
  type: string;
  attributes: HealthcarePersonnelAttributes;
}

interface HealthcarePersonnelAttributes {
  name: string;
  profile_type: string;
  experience: number;
  location_limit: number|null; 
  profile_type_specific_data: ProfileTypeSpecificData;
  age: number;
  gender: string;
  identification_no: string;
  account_id: number;
  price: number;
  email: string;
  phone_number: number;
  full_phone_number: string;
  identification_document: string|null;
  identification_document_url: string|null;
  address: {
    data: Address;
  };
  language_attributes: Language[];
  working_shifts: WorkingShift[]; 
  shift_availabilities: ShiftAvailability[];
  profile_image: string|null; 
  profile_image_url: string|null; 
  educational_degrees: EducationalDegree[];
}

interface ProfileTypeSpecificData {
  connect_with_hospital: boolean;
  sos_available: boolean;
  hospital_id: number;
  hospital: Hospital;
  department_id: number;
  department: Department;
  specialization_id: number;
  specialization: Specialization;
  request_status: string;
  online_consultation_fee: number;
}

interface Hospital {
  id: number;
  name: string;
}

interface Department {
  id: number;
  name: string;
}

interface Specialization {
  id: number;
  name: string;
}

interface Address {
  id: string;
  type: string;
  attributes: AddressAttributes;
}

interface AddressAttributes {
  address: string;
  city: string;
  district: string;
  latitude: number | null;
  longitude: number | null;
  pincode: string;
}

interface Language {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

interface WorkingShift {
}

interface ShiftAvailability {
  id: number;
  start_time: string;
  end_time: string;
  healthcare_personnel_id: number;
  created_at: string;
  updated_at: string;
  duration: string;
  shift: string;
}

interface EducationalDegree {
}


interface HealthcarePersonnelUser {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
      profile_type: string;
      specialist: string;
      profile_image: string | null;
      languages: string[];
      price: number | null;
      rating: number | null;
      shift: string | null;
    };
}

interface BedType {
  id: number | null;
  bed_name: string;
  total_beds: number;
  booked_beds: number;
  available_beds: number;
}

interface IHospitalDashboardData {
  data: {
    total_reservations: number;
    todays_reservations: number;
    total_beds_booked: number;
    available_bed_types: BedType[];
  };
}



// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  userType: string;
  errorMessage: string;
  data:any;
  banner:string;
  hpRole: string;
  isShiftStart:boolean;
  openDialog:boolean;
  updateShiftStart:any;
  graphData:any;
  isHealthDetailsDialogOpen:boolean;
  currentPage:string;
  ayahDashboardDetails:any;
  totalBookingDetails:any;
  allBooking:any;
  ayahDashboardPaitentHelthDetails:any;
  bookingId:string;
  healthDetails:any;
  selectedMonth:number;
  selectedYear:number;
  showYear:boolean;
  showPopup:boolean;
  showConsultationType:boolean;
  isConnectedHospital:boolean;
  showHospitalType: boolean;
  searchValue:string;
  isSearchActive:boolean;
  role:boolean;
  paymentType:string;
  doctorList:HealthcarePersonnel[];
  healthCarePersonList:HealthcarePersonnelUser[];
  isAdvanceSearchLoading:boolean;
  hospitalDashboardData: IHospitalDashboardData|null;
  onlineTotalMoney:number;
  offlineTotalMoney:number;
  hpUserRole:string;
  searchedTerm: string;
  advanceSearchPageCurrentPage:number;
  prevScrollTop:number;
  doctorCount:number;
  totalHealthcarePersonCount:number;
  isLoading:boolean;
  roleType:string;
  userRole:string;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  apiServiceItemCallId: string = "";
  apiAyahDashboardDetailsCallId: string = "";
  apiAyahDashboardBookingDetailsCallId: string = "";
  apiAyahDashboardTotalBookingDetailsCallId: string = "";
  apiUpdateStatusCallId: string = "";
  apiPaitentHealthDetailsCallId: string = "";
  apiGetDoctorsListCallId:string = "";
  apiGetHealthCarePersonListCallId:string ="";
  apiGetHospitalDashboardDataCallId:string = "";
  apiGraphApiCallId:string = "";
  apiCaretakerGraphApiCallId: string = ""
  searchInputRef: React.RefObject<HTMLInputElement> = React.createRef();
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      userType: "",
      errorMessage:"",
      data:[],
      banner:"",
      hpRole:"",
      isShiftStart:true,
      openDialog:false,
      updateShiftStart:[],
      isHealthDetailsDialogOpen:false,
      currentPage:"Dashboard",
      ayahDashboardDetails:{},
      totalBookingDetails:{},
      allBooking:{},
      ayahDashboardPaitentHelthDetails:{},
      graphData: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
        ],
        datasets: [
          {
            label: "Online",
            backgroundColor: "#014866",
            data: [0, 0, 0, 0, 0, 0],
          },

          {
            label: "Offline",
            backgroundColor: "#4D6EFF",
            data: [0, 0, 0, 0, 0, 0],
          },
        ],
      }, 
      bookingId:'',
      healthDetails: {},   
      selectedMonth:new Date().getMonth(),
      selectedYear:new Date().getUTCFullYear(),
      showYear:false,
      showPopup:false,
      showConsultationType:false,
      isConnectedHospital:false,
      showHospitalType:false,
      searchValue:"",
      isSearchActive:false,
      role:false,
      paymentType:"",
      doctorList:[],
      isLoading:false,
      healthCarePersonList:[],
      isAdvanceSearchLoading:false,
      hospitalDashboardData:null,
      onlineTotalMoney:0,
      offlineTotalMoney:0,
      hpUserRole:"",
      searchedTerm:'',
      advanceSearchPageCurrentPage:1,
      prevScrollTop:0,
      totalHealthcarePersonCount:0,
      doctorCount:0,
      roleType:"",
      userRole:""
    };
    this.scrollContainerRef = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // Customizable Area Start
      this.callNextStep()
    // Customizable Area End
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token, loading: true }, () => {
        this.getDashboardData();
      });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.setState({isLoading:false})
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiResponseHandlers: { [key: string]: (responseJson: any, errorReponse: any) => void } = {
        [this.apiServiceItemCallId]: (responseJson) => {
            this.setState({ data: responseJson.services, banner: responseJson.banner });
        },
        [this.apiAyahDashboardDetailsCallId]: (responseJson) => {
            this.setState({ ayahDashboardDetails: responseJson.data.attributes, loading: false });
        },
        [this.apiAyahDashboardBookingDetailsCallId]: (responseJson) => {
            this.setState({ allBooking: responseJson.data });
        },
        [this.apiAyahDashboardTotalBookingDetailsCallId]: (responseJson) => {
            this.setState({ totalBookingDetails: responseJson.data,loading:false });
        },
        [this.apiUpdateStatusCallId]: () => {
            this.setState({ bookingId: "" });
            this.fetchAyahDashboardDetails();
        },
        [this.apiPaitentHealthDetailsCallId]: (responseJson) => {
          this.setState({ healthDetails: responseJson.data,isHealthDetailsDialogOpen:true });
        },
        [this.apiGetDoctorsListCallId]:(responseJson:DoctorListResponse)=>{
          this.setState((prevState)=>{
            return {
              doctorList:[...prevState.doctorList,...responseJson.healthcare_personnels.data], 
              doctorCount:responseJson.meta.total_count, 
              isAdvanceSearchLoading : false
            }
          })
        },
        [this.apiGetHealthCarePersonListCallId]:(responseJson:HealthcarePersonnelListResponse)=>{
          this.setState((prevState)=>{
            return {
              healthCarePersonList:[...prevState.healthCarePersonList,...responseJson.search_results.data],
              totalHealthcarePersonCount:responseJson.meta.total_count, 
              isAdvanceSearchLoading : false
            }
          })
        },
        [this.apiGetHospitalDashboardDataCallId]:(responseJson:IHospitalDashboardData)=>{
          this.setState({ hospitalDashboardData : responseJson })
        },
        [this.apiGraphApiCallId]: (responseJson) => {
          const label = responseJson.data.attributes.months?.map((word:string) => word.substring(0,3))
          const online_total_earned = this.getOnlinePrice(responseJson);
          const offline_total_earned = this.getOfflinePrice(responseJson);
          const onlinePrice = this.transformedData(responseJson.data.attributes.online);
          const offlinePrice = this.transformedData(responseJson.data.attributes.offline);
          let dataSets = [
            {
              label: "Offline Price",
              backgroundColor: "#014866",
              data: offlinePrice,
            },
            {
              label: "Online Price",
              backgroundColor: "#4D6EFF",
              data: onlinePrice,
            },

            ]

          const graphData = {
              labels: label,
              datasets: dataSets,
          }
          this.setState({graphData,onlineTotalMoney:parseInt(online_total_earned),offlineTotalMoney:parseInt(offline_total_earned)	})
        },
        [this.apiCaretakerGraphApiCallId]: (responseJson) => {
          const graphLabel = responseJson.data.attributes.months?.map((word:string) => word.substring(0,3))
          const total_earned = responseJson?.data?.attributes.total_earned === null ? 0 : responseJson?.data?.attributes.total_earned;
          const totalEarnedPrice = this.transformedData(responseJson.data.attributes.amounts);
          const graphData = {
              labels: graphLabel,
              datasets: [
                {
                  label: "Offline Price",
                  backgroundColor: "#014866",
                  data: totalEarnedPrice,
                },
          ],
          }
          this.setState({graphData,offlineTotalMoney:parseInt(total_earned)})
        }
      };

      if (!responseJson.errors) {
        const handler = apiResponseHandlers[apiRequestCallId];
        if (handler) {
            handler(responseJson,errorReponse);
        }
    } else {
        this.setErrorMessage(responseJson);
        if(responseJson?.errors === configJSON.InvalidUserMessage || responseJson?.errors?.message === configJSON.TokenHasExpired){
          await this.clearLocalStorageAndLogout();
        }
    }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidUpdate(prevProps: Props, prevState: any) {
    const { advanceSearchPageCurrentPage } = this.state;
    if (prevState.advanceSearchPageCurrentPage !== advanceSearchPageCurrentPage) {
      try {
        if (!this.state.isAdvanceSearchLoading) {
          this.state.userType === configJSON.userType.Patient ? await this.getHealthCarePersons(): await this.getDoctorSearchResults()
        }
      } catch (error) {
        this.showAlert("Error fetching hospital data:", "Something went wrong");
      }
    }
  }

  getOnlinePrice = (responseJson:any) => {
    return responseJson?.data?.attributes.online_total_earned === null ? 0 : responseJson?.data?.attributes.online_total_earned;
  }

  getOfflinePrice = (responseJson:any) => {
    return responseJson?.data?.attributes.offline_total_earned === null ? 0 : responseJson?.data?.attributes.offline_total_earned;
  }
  
  transformedData = (data:any) => {
    return data.map((value:any) => value > 0 ? value * 1000 : value);
  };

  setErrorMessage = (responseJson:any) => {
    this.setState({ errorMessage: responseJson.errors ? responseJson.errors.message : responseJson.error.message,loading:false });
  }
  getAPIData = (responseJson: { errors:{message:string}; data: DashboardDataType },errorReponse: string |undefined) => {
    if (responseJson && !responseJson.errors && responseJson.data) {
      if (responseJson.data.length === 0) {
        this.setState({
          errorMsg: "Data Not Found",
          loading: false
        });
      } else {
        this.setState({
          dashboardData: responseJson.data,
          errorMsg: "",
          loading: false
        });
      }
    } else {
      if (errorReponse === undefined) {
        this.setState({
          errorMsg: "Something went wrong",
          loading: false
        });
      } else {
        this.setState({
          errorMsg: errorReponse,
          loading: false
        });
      }
    }
  }

  clearLocalStorageAndLogout = async () => {
    await removeStorageData('authToken');
    await removeStorageData('isLogin');
    await removeStorageData('userType')
    await removeStorageData('isSignUp')
    await removeStorageData('phoneNumber')
    await removeStorageData('doctorId')
    await removeStorageData('hpUserRole')
    await removeStorageData('priceMode')
    await removeStorageData('redirectFromDashboard')
    await removeStorageData('userName')
    await removeStorageData('serviceType')
    await removeStorageData('isConnectedHospital')
    await removeStorageData('profile');
    this.goToBlock('UserRole');
  }

  goToBlock = (blockName: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), blockName);
    message.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
    );
    this.send(message);
  }

  getServiceData = async() => {
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiServiceItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.service
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

    callNextStep =async() => {
      this.getServiceData();
      const userType = await getStorageData("userType");
      this.setState({userType},()=>{
        if(this.state.userType === 'hospital'){
          this.getHospitalDashboardData();
        }
      })
    }

    redirectToPage = (name:string,path:string) => {
      setStorageData("redirectFromDashboard", JSON.stringify(true));
      setStorageData("serviceType", name);
      removeStorageData("filters");
      removeStorageData("hpFilters");
      const navigateTo = new Message(getName(MessageEnum.NavigationMessage));
      navigateTo.addData(getName(MessageEnum.NavigationTargetMessage), path);
      navigateTo.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(navigateTo); 
    }

    openService = (name:string|undefined) => {
      if (name === "Hospitals") {
        removeStorageData("filters")
        this.props.navigation.navigate("Reservations2Booking");
      } else if (name === "Nurses" || name === "Ayah") {
        this.redirectToPage(name,"Reservations2NurseBooking")
      } else if (name === "Doctors"){
        this.setState({showPopup:true,roleType:"Appointment",userRole:name})
      }else if (name === "Physiotherapist") {
        this.setState({showConsultationType:true,roleType:"Home Visit",userRole:name})
      }else if(name === "Dietician") {
        this.setState({roleType:"Home Visit",userRole:name,showConsultationType:true})
      }
    }


    handleCloseHealthDetailsDialog = () => {
      this.setState((prev)=>({...prev,isHealthDetailsDialogOpen:false}))
    }

  handleUpdateShift = (index: number, shiftType: string) => {
    this.setState(prevState => {
      const updatedShiftStart = [...prevState.updateShiftStart];
      if (shiftType === 'start') {
        updatedShiftStart[index] = true;
      } else if (shiftType === 'end') {
        updatedShiftStart[index] = false;
      }

      return { updateShiftStart: updatedShiftStart };
    });
  };

    handelShiftStatus = () => {
      this.setState((prev) => ({ ...prev, isShiftStart: !prev.isShiftStart}));
    }  

    showRecentTransaction = () => {
      if (this.state.totalBookingDetails.length > 0) {
        this.setState({currentPage: "Recent Transactions",loading:true})
        this.fetchAyahDashboardTotalBookingDetails();
      }
    }

    showEmergencyContact = () => {
      this.setState({openDialog:!this.state.openDialog})
    }

    openDashboard = (heading:string) => {
      if(heading === "Recent Transactions"){
        this.setState({currentPage:"Total Money",loading:true})
        this.fetchAyahDashboardTotalBookingDetails();
      }else{
        this.setState((prev) => ({ ...prev, currentPage: "Dashboard" }))
      }
    }

    showTotalBooking = () => {
      this.setState({currentPage:"Total Bookings"})
    }

    showAllBooking = (bookingType:string) => {
      this.setState({currentPage:bookingType,loading:true})
      this.fetchAyahDashboardBookingDetails(bookingType)
    }

    showTotalMoney = () => {
      this.setState({currentPage:"Total Money"})
      this.callGraph()
    }

    callGraph = async () => {
      const hpUserRole = await this.getHpUserRole();
      this.setState({hpUserRole})
      if (hpUserRole !== "ayah" && hpUserRole !== "nurse") {
        this.callMedicsGraph()
      }else{
        this.callCaretakerGraph()
      }
    }

    callMedicsGraph = async () => {
      const token = await getStorageData("authToken");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiGraphApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.graphApi
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.dashboarApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }

    callCaretakerGraph = async () => {
      const token = await getStorageData("authToken");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCaretakerGraphApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.caretakerGraphApi
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.dashboarApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }

    fetchAyahDashboardDetails = () => {
      this.setState({loading: true}, async() => {
      const token = await getStorageData("authToken");
      const hpUserRole = await this.getHpUserRole();
      let url = configJSON.ayahDashboardDetailsEndPoint

      if(hpUserRole !== "ayah" && hpUserRole !== "nurse"){
        url = configJSON.medicDashboardGetApi
      }
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiAyahDashboardDetailsCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        url
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.dashboarApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    })
    }

    fetchAyahDashboardBookingDetails = async(bookingType:string) => {
      const hpUserRole = await this.getHpUserRole();
      if(hpUserRole !== "nurse" && hpUserRole !== "ayah"){
        this.callMedicBookingUpdateApi(bookingType)
      }else{
        this.callCaretakerBookingUpdateApi(bookingType)
      }
    }

    callCaretakerBookingUpdateApi = async(bookingType:string) => {
      const token = await getStorageData("authToken");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiAyahDashboardBookingDetailsCallId = requestMessage.messageId;
      if(bookingType==="Upcoming Bookings"){
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.ayahDashboardUpcomingBookingDetailsEndPoint
        );
      }else{
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.ayahDashboardPastBookingDetailsEndPoint
        );
      }
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.dashboarApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }

    callMedicBookingUpdateApi = async(bookingType:string) => {
      const token = await getStorageData("authToken");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiAyahDashboardBookingDetailsCallId = requestMessage.messageId;
      if(bookingType==="Upcoming Bookings"){
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.medicsDashboardUpcomingBookingEndPoint
        );
      }else{
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.medicsDashboardPastBookingEndPoint
        );
      }
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.dashboarApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }

    fetchAyahDashboardTotalBookingDetails = async() => {
      const token = await getStorageData("authToken");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token
      };

      const hpUserRole = await this.getHpUserRole();

      let url = `${configJSON.ayahDashboardTotalBookingDetailsEndPoint}?month=${this.state.selectedMonth+1}&year=${this.state.selectedYear}`;

      if(hpUserRole !== "nurse" && hpUserRole !== "ayah"){
        url = `${configJSON.medicDashboardTotalBookingDetailsEndPoint}?month=${this.state.selectedMonth+1}&year=${this.state.selectedYear}`;
      }

      if(this.state.paymentType !== ""){
        url = `${url}&payment_type=${this.state.paymentType}`
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiAyahDashboardTotalBookingDetailsCallId = requestMessage.messageId;
    
      if(this.state.currentPage == "Recent Transactions"){
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${url}`
        );
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          url
        );
      }

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.dashboarApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }

    updateBookingStatus = async (userId:number,status:string,bookingId:string) => {
      if(status === 'accepted'){this.setState({bookingId})}
      const hpUserRole = await this.getHpUserRole();
      let url = configJSON.updateHealthcarePersonnelBooking
      let method = configJSON.patchApiMethodType
      if(hpUserRole !== "ayah" && hpUserRole !== "nurse"){
        url = configJSON.updateMedicHealthcarePersonnelBooking
        method= configJSON.putApiMethodType
      }
      const token = await getStorageData("authToken");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token 
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiUpdateStatusCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        url
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      const httpBody = {
          id:userId,
          status
      }

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }

    getWeekdayName= (dateString:string) => {
      const date = new Date(dateString);
      const weekdayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      return weekdayNames[date.getDay()];
    }

    formatDate = (dateString:string) => {
      const date = new Date(dateString);
      const day = date.getDate();
    
      const daySuffix = (day:number) => {
        if (day > 3 && day < 21) return 'th'; 
        switch (day % 10) {
          case 1: return 'st';
          case 2: return 'nd';
          case 3: return 'rd';
          default: return 'th';
        }
      };
    
      const months = ["Jan.", "Feb.", "Mar.", "Apr.", "May.", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];
    
      const formattedDate = `${day}${daySuffix(day)} ${months[date.getMonth()]} ${date.getFullYear()}`;
      return formattedDate;
    }
    
    bookingTypeStatus = () => {
      if(this.state.currentPage === "Upcoming Bookings") return true;
      if(this.state.currentPage === "Past Bookings") return true;
      return false;
    }

    fetchRole =  async() => {
      const hpUserRole = await getStorageData("hpUserRole");
      let role = false
      if(hpUserRole.toLowerCase() !== "ayah" && hpUserRole.toLowerCase() !== "nurse"){
        role = true
      }
      this.setState({role})
    }

    getHpUserRole = async () => {
      const hpUserRole = await getStorageData("hpUserRole");
      return hpUserRole.toLowerCase();
    }

    fetchAyahDashboardPaitentHealthDetails = async(patientId:string) => {
      const token = await getStorageData("authToken");
      
      const hpUserRole = await this.getHpUserRole();

      let url = configJSON.ayahDashboardPaitentHealthDetailsEndPoint

      if(hpUserRole !== "ayah" && hpUserRole !== "nurse"){
        url = configJSON.medicsDashboardPaitentHealthDetailsEndPoint
      }
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiPaitentHealthDetailsCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${url}?id=${parseInt(patientId)}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.dashboarApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }

    getMonthNumber = (monthName: string): number => {
      const monthMap: { [key: string]: number } = {
          January: 1,
          February: 2,
          March: 3,
          April: 4,
          May: 5,
          June: 6,
          July: 7,
          August: 8,
          September: 9,
          October: 10,
          November: 11,
          December: 12,
      };
  
      return monthMap[monthName] ?? -1; 
  };

  cardDateFormat(dateString:string) {
    const date = new Date(dateString)
  
    const day = String(date.getDate()).padStart(2, '0')
    const weekday = date.toLocaleDateString('en-US', { weekday: 'short' })
    const month = date.toLocaleDateString('en-US', { month: 'short' })
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0')
  
    const period = hours >= 12 ? 'PM' : 'AM'
    const formattedHours = hours % 12 || 12
  
    const formatedDateAndTime = `${weekday}, ${day} | ${formattedHours}:${minutes}${period}`
  
    const FormatedDate = `${formattedHours}:${minutes}${period}`
  
    const formatedDateAndMonth = `${weekday}, ${day} ${month}`
  
    return {
      formatedDateAndTime,
      FormatedDate,
      formatedDateAndMonth,
    }
  }

  selectedPreviousMonth = () => {
    this.setState({ loading:true ,selectedMonth: this.state.selectedMonth - 1 },() => {
      this.fetchAyahDashboardTotalBookingDetails();
    })
  }

  selectedNextMonth = () =>{
    this.setState({ loading:true ,selectedMonth: this.state.selectedMonth + 1 },() => {
      this.fetchAyahDashboardTotalBookingDetails();
    })
  }

  selectedYear = (year:number) => {
    this.setState({ showYear: false, selectedYear: year,loading:true },() => {
      this.fetchAyahDashboardTotalBookingDetails();
    })
  }

  formatBookingDate(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { 
        weekday: 'short', 
        day: '2-digit', 
        month: 'short' 
    };
    const dateParts = date.toLocaleDateString('en-US', options).split(' ');

    const formattedDate = `${dateParts[0]} ${dateParts[1]} ${dateParts[2]}`;

    return formattedDate;
}

  selectConsultationType = () => {
    this.setState({showPopup:false,showHospitalType:true});
  }

  hidePopup = () => {
    this.setState({showPopup:false,showHospitalType:false,showConsultationType:false});
  }

  handelIsConnectedHospital = (isConnectedHospital:boolean) => {
    this.setState({isConnectedHospital,showConsultationType:true});
    setStorageData("isConnectedHospital", JSON.stringify(isConnectedHospital));
  }

  handelIsAppointmentHospital = (setType:string) => {
      setTimeout(() => {if(this.state.roleType==="Appointment"){this.redirectToPage('Doctors',"Reservations2DoctorBooking")}else{this.redirectToPage(this.state.userRole,"Reservations2MedicBooking")}}, 300);
      setStorageData("priceMode", setType);
  }

  onScroll = () => {
    const element = this.scrollContainerRef.current;
    if (element && !this.state.isAdvanceSearchLoading) {
      const { clientHeight, scrollTop, scrollHeight } = element;
      const isCountEqual = this.state.userType === configJSON.userType.Patient? this.state.healthCarePersonList?.length < this.state.totalHealthcarePersonCount : this.state.doctorList.length < this.state.doctorCount;
      if (scrollTop > this.state.prevScrollTop && scrollTop + clientHeight >= scrollHeight - 10 && isCountEqual) {
        this.setState(prevState => ({
          advanceSearchPageCurrentPage: Math.max(1, prevState.advanceSearchPageCurrentPage + 1),
        }));
      }
      this.setState({ prevScrollTop: scrollTop });
    }
  }

  getHospitalDashboardData = async ()=>{
    this.setState({isLoading:true})
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetHospitalDashboardDataCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.HospitalDashboardEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.dashboarApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDoctorSearchResults = async() => {
    this.setState({isAdvanceSearchLoading:true})
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetDoctorsListCallId = requestMessage.messageId;
    if(this.state.searchValue.trim()){
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.doctorSearchEndPoint}?name=${this.state.searchValue}&per_page=10&page=${this.state.advanceSearchPageCurrentPage}` );
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.dashboarApiMethodType);
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    return true;
  }

  getHealthCarePersons = async() => {
    this.setState({isAdvanceSearchLoading:true})
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetHealthCarePersonListCallId = requestMessage.messageId;
    if(this.state.searchValue.trim()){
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.patientAdvanceSearchEndPoint}?name_location=${this.state.searchValue}&per_page=10&page=${this.state.advanceSearchPageCurrentPage}` );
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.dashboarApiMethodType);
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    return true;
  }


  debounce<T extends (...args: string[]) => void>(func: T, delay: number): (...args: Parameters<T>) => void {
    let timer: ReturnType<typeof setTimeout>;
    return function (...args: Parameters<T>): void {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  handleChangeSearch = () => {
    this.state.userType === configJSON.userType.Patient ? this.getHealthCarePersons():this.getDoctorSearchResults()
  }

  onSearch: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined = (event) => {
    const searchedTerm = event.target.value;
    this.setState({
      searchValue : searchedTerm, 
      isSearchActive : searchedTerm.trim() ? true : false, 
      doctorList:  [],
      healthCarePersonList: [],
      advanceSearchPageCurrentPage:1,
      doctorCount:0,
      totalHealthcarePersonCount:0
    });
    if(searchedTerm.trim()){
      const debouncedFunction = this.debounce(this.handleChangeSearch,500)
      debouncedFunction();
    }
  }

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter'&& this.state.searchValue) {
      this.setState({isSearchActive:true,advanceSearchPageCurrentPage:1,})
      this.handleChangeSearch()
    }
  };

  clearSearch=()=>{
    this.setState({isSearchActive:false,searchValue:'',doctorList:[],totalHealthcarePersonCount:0,doctorCount:0, healthCarePersonList: [],advanceSearchPageCurrentPage:1})
  }

  removeListOnBlur = ()=>{
    this.setState({ isSearchActive: false })
  }

  setPaymentTypeNull = ()=>{
    this.setState({ paymentType: "" })
    this.fetchAyahDashboardTotalBookingDetails()
  }

  setPaymentTypeOffline = ()=>{
    this.setState({ paymentType: "offline" })
    this.fetchAyahDashboardTotalBookingDetails()
  }

  setPaymentTypeOnline = ()=>{
    this.setState({ paymentType: "online" })
    this.fetchAyahDashboardTotalBookingDetails()
  }

  moveToPlans = () => {
    const messages = new Message(getName(MessageEnum.NavigationMessage));
    messages.addData(getName(MessageEnum.NavigationTargetMessage),"Subscription");
    messages.addData( getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(messages);
  }

  formatYAxisTicks = (value:any) => {
    return `₹${value / 1000}k`;
  };

  tooltipItem = (tooltipItem:any, data:any) =>{
    const datasetLabel = data.datasets[tooltipItem.datasetIndex].label;
    const value = tooltipItem.yLabel;
    return `${datasetLabel}: Rs. ${value}`;
  }
  
  // Customizable Area End

}
