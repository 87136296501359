import * as React from "react";
// Customizable Area Start
import {
  withStyles,
  Box,
  Typography,
  Grid,
  ThemeProvider,
  createTheme,
  Button,
  Popover
} from "@material-ui/core";
import PromocodesAndDiscountController, {
  Props
} from "./PromocodesAndDiscountController";
import Sidebar from "../../../../components/src/Sidebar.web";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
const subscriptionData = [
  {
    roleType: "Nurse",
    price: "₹ 4000",
    message: "Only Morning and Evening Shift"
  },
  {
    roleType: "Nurse",
    price: "₹ 4600",
    message: "All shifts Morning, Evening & Night"
  },
  {
    roleType: "Ayah",
    price: "₹ 2800",
    message: "Senior Citizen care, mother & child care"
  },
  {
    roleType: "MBBS Doctor",
    price: "₹ 4000",
    message: ""
  },
  {
    roleType: "Dietician",
    price: "₹ 1500",
    message: ""
  },
  {
    roleType: "Physiotherapist",
    price: "₹ 3499",
    message: ""
  }
];

const details = [
  { label: "Name", value: "Andrews" },
  { label: "Date", value: "09, Jan 2023" },
  { label: "Price", value: "Rs 4000.00" }
];
// Customizable Area End

export class Subscription extends PromocodesAndDiscountController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
          <Grid container className={classes.subscriptionLeftWrapper}>
            <Grid item xs={1}>
              <Sidebar />
            </Grid>
            <Grid item xs={11} className={classes.subscriptionRightWrapper}>
              <Box>
                <Box className={classes.subscripionContainer}>
                  <Box className={classes.headingContainer}>
                    <ArrowBackIosIcon
                      className={classes.backBtn}
                      data-test-id="backToDashboard"
                      onClick={this.backToDashboard}
                    />
                    <Typography
                      variant="h4"
                      className={classes.headingContainer}
                    >
                      Subscription Plans
                    </Typography>
                  </Box>
                </Box>
                <Grid container>
                  <Grid item xl={6} md={6} sm={12} xs={12}>
                    <Box>
                      {subscriptionData.map((data: any, index: number) => (
                        <Box
                          key={index}
                          className={
                            this.state.selectedIndex === index
                              ? classes.selectedCard
                              : classes.card
                          }
                          data-test-id={`selectSubscription${index}`}
                          onClick={() => this.handelSubscription(data, index)}
                        >
                          <Box className={classes.subContainer}>
                            <Typography
                              variant="h6"
                              className={classes.userRole}
                            >
                              {data.roleType} Subscription
                            </Typography>
                            <Typography variant="h6" className={classes.price}>
                              {data.price}
                            </Typography>
                          </Box>
                          <Typography variant="h6" className={classes.message}>
                            {data.message}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                    <Box className={classes.note}>
                      <span className={classes.noteBold}>Note:</span> Valid till
                      10 Healthcare personnel visit/consultation (online/offline)
                      or 3 months, which ever is earliest.
                    </Box>
                    <Box className={classes.btnContainer}>
                      <Button className={classes.continueBtn}>Continue</Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Popover
                className={classes.hpNewPaperPropsClass}
                data-test-id="hpPopover"
                open={this.state.showPopover}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "center"
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "center"
                }}
              >
                {this.state.selectedData !== null && (
                  <Box className={classes.hpPopoverContainer}>
                    <Box>
                      <Typography className={classes.heading}>
                        {this.state.selectedData.roleType} Subcription
                      </Typography>
                      <Typography className={classes.subHeading}>
                        {this.state.selectedData.message}
                      </Typography>
                      <Box className={classes.divider} />
                      <Box className={classes.detailsContainer}>
                        <Box style={{ width: "48%" }}>
                          {details.map((detail, index) => (
                            <Typography
                              key={`label-${index}`}
                              className={classes.label}
                            >
                              {detail.label}
                            </Typography>
                          ))}
                        </Box>
                        <Box style={{ width: "4%" }}>
                          {details.map((_, index) => (
                            <Typography
                              key={`colon-${index}`}
                              className={classes.colon}
                            >
                              :
                            </Typography>
                          ))}
                        </Box>
                        <Box style={{ width: "48%" }}>
                          {details.map((detail, index) => (
                            <Typography
                              key={`value-${index}`}
                              className={classes.details}
                            >
                              {detail.value}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                      <Button
                        className={classes.paymentBtn}
                        onClick={this.closePopover}
                        data-test-id="closePopup"
                      >
                        Select Payment
                      </Button>
                    </Box>
                  </Box>
                )}
              </Popover>
            </Grid>
          </Grid>
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  subscriptionLeftWrapper: {
    overflowX: "hidden" as "hidden",
    height: "100%"
  },
  subscriptionRightWrapper: {
    height: "100%",
    padding: "50px",
    overflowY: "scroll" as "scroll",
    scrollbarColor: "#fff #fff",
    scrollbarWidth: "thin" as "thin",
    "& ::-webkit-scrollbar-button": {
      display: "none"
    }
  },
  subscripionContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "50px"
  },
  headingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "25px",
    marginTop: "10px",
    caretColor:"transparent",
    fontFamily: "SF Pro Text Bold",
    fontSize: "28px",
    color: "#292929",
  },
  backBtn: {
    display: "flex",
    alignItems: "center",
    fontSize: "22px",
    padding: "13px 9px 13px 17px",
    justifyContent: "center",
    borderRadius: "8px",
    border: "1px solid #E9E9E9",
    caretColor: "transparent",
    cursor: "pointer"
  },
  selectedCard: {
    width: "100%",
    maxWidth: "660px",
    height: "60px",
    padding: "20px",
    borderRadius: "8px",
    border: "1px solid #014866",
    marginBottom: "20px",
    backgroundColor: "#D9E4E8"
  },
  card: {
    width: "100%",
    maxWidth: "660px",
    height: "60px",
    padding: "20px",
    borderRadius: "8px",
    border: "1px solid #DEDEDE",
    marginBottom: "20px"
  },
  userRole: {
    width: "70%",
    fontFamily: "SF Pro Text Bold",
    fontSize: "20px",
    color: "#292929",
    caretColor: "transparent"
  },
  price: {
    width: "30%",
    fontFamily: "SF Pro Text Bold",
    fontSize: "20px",
    color: "#014866",
    textAlign: "right" as "right",
    caretColor: "transparent"
  },
  message: {
    fontFamily: "SF Pro Text Medium",
    fontSize: "16px",
    color: "#808080",
    caretColor: "transparent"
  },
  subContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "10px"
  },
  note: {
    fontFamily: "SF Pro Text Regular",
    fontSize: "18px",
    color: "#292929",
    marginTop: "35px",
    caretColor: "transparent"
  },
  noteBold: {
    caretColor: "transparent",
    fontFamily: "SF Pro Text Medium"
  },
  btnContainer: {
    display: "flex",
    marginTop: "40px",
    gap: "25px"
  },
  paymentBtn: {
    border: "none",
    color: "#FFFFFF",
    width: "75%",
    display: "flex",
    margin: "35px auto 0px",
    backgroundColor: "#014866",
    fontSize: "18px",
    fontFamily: "SF PRO Text Bold",
    height: "52px",
    alignItem: "center" as "center",
    alignContent: "center" as "center",
    borderRadius: "8px",
    caretColor: "transparent",
    "&.MuiButton-root:hover": {
      backgroundColor: "#014866",
      color: "#FFFFFF"
    },
    textTransform: "capitalize" as "capitalize",
    cursor: "pointer"
  },
  continueBtn: {
    border: "none",
    color: "#FFFFFF",
    width: "170px",
    backgroundColor: "#014866",
    fontSize: "18px",
    fontFamily: "SF PRO Text Bold",
    height: "52px",
    alignItem: "center" as "center",
    alignContent: "center" as "center",
    marginBottom: "15px",
    borderRadius: "8px",
    caretColor: "transparent",
    "&.MuiButton-root:hover": {
      backgroundColor: "#014866",
      color: "#FFFFFF"
    },
    textTransform: "capitalize" as "capitalize",
    cursor: "pointer"
  },
  hpNewPaperPropsClass: {
    "& > div:nth-child(1)": {
      backgroundColor: "#050505a3 !important"
    },
    "& .MuiPopover-paper": {
      borderRadius: "30px",
      overflow: "unset"
    }
  },
  hpPopoverContainer: {
    padding: "35px"
  },
  heading: {
    fontFamily: "SF Pro Text Bold",
    fontSize: "28px",
    color: "#292929",
    caretColor: "transparent"
  },
  subHeading: {
    fontFamily: "SF Pro Text Medium",
    fontSize: "16px",
    color: "#292929",
    caretColor: "transparent"
  },
  divider: {
    width: "370px",
    height: "1px",
    backgroundColor: "#E7E2E2",
    marginTop: "15px",
    caretColor: "transparent",
    marginBottom: "15px"
  },
  detailsContainer: {
    display: "flex",
    gap: "5px",
    width: "100%",
    marginBottom: "15px"
  },
  label: {
    lineHeight: "2.0",
    fontFamily: "SF Pro Text Medium",
    fontSize: "18px",
    caretColor: "transparent",
    color: "#292929"
  },
  colon: {
    lineHeight: "2.0",
    fontFamily: "SF Pro Text Medium",
    fontSize: "18px",
    color: "#808080",
    caretColor: "transparent"
  },
  details: {
    lineHeight: "2.0",
    fontFamily: "SF Pro Text Medium",
    fontSize: "18px",
    caretColor: "transparent",
    color: "#808080"
  }
};

export default withStyles(styles)(Subscription);
// Customizable Area End
