import React, { useState } from 'react';
// Customizable Area Start
import { Button, Grid, Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { HomeScreen, HomeCureLogo, pexelsCottonbroStudio } from './assets';
import UserroleController, { Props } from "./UserroleController";
// Customizable Area End

export class UserRole extends UserroleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    setTimeout(() => {
      this.setState({ showForm: true });
    }, 3000);
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box className={classes.container}>
        {!this.state.showForm ? (<Box className={classes.homeCureContainer}>
          <img src={HomeScreen} alt='HomeCure Logo' className={classes.imageStyle} />
        </Box>
        ) : (
          <Grid container >
            <Grid item lg={6} md={6} sm={6} xs={12} className={classes.gridHeight}>
              <img
                src={HomeCureLogo}
                alt='HomeCure Logo'
                className={classes.homecureLogo}
              />
              <Box className={classes.cardContainer}>
                <Box className={classes.heading}>
                  <Box data-testid="selectUserType" className={classes.userType}>
                    <span className={classes.textBold}>Hi,</span> Choose your user type
                  </Box>
                </Box>
                <Button
                  data-testid="patient"
                  key="patient"
                  variant='outlined'
                  className={`${classes.selectionButton} ${this.state.buttonSelection === "patient" ? classes.selectedButton : ''}`}
                  onClick={() => this.setButtonSelection("patient")}
                >
                  Patient
                </Button>
                <Button
                  data-testid="hospital"
                  key="hospital"
                  variant='outlined'
                  className={`${classes.selectionButton} ${this.state.buttonSelection === "hospital" ? classes.selectedButton : ''}`}
                  onClick={() => this.setButtonSelection("hospital")}
                >
                  Hospital
                </Button>
                <Button
                  data-testid="healthcare-personnel"
                  key="healthcare_personnel"
                  variant='outlined'
                  className={`${classes.selectionButton} ${this.state.buttonSelection === "healthcare_personnel" ? classes.selectedButton : ''}`}
                  onClick={() => this.setButtonSelection("healthcare_personnel")}
                >
                  Healthcare Personnel
                </Button>
                <Button
                  data-testid="typeBtn"
                  variant='contained'
                  color='primary'
                  className={classes.customButton}
                  onClick={this.handleButtonClick}
                >
                  CONTINUE
                </Button>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12} className={classes.gridHeight}>
              <Box className={classes.rightContainer} >
                <Box
                  className={classes.rightContainerBackground}
                ></Box>
                <Typography variant='h6' data-testid="contactUs" className={classes.contactUs} onClick={this.moveToContactUs}>
                  Contact Us
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
      // Customizable Area End
    );
  }
};

const useStyles = {
  // Customizable Area Start
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
  homeCureContainer: {
    backgroundColor: '#014866',
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center' as 'center',
    justifyContent: 'center' as 'center',
  },
  banner: {
    height: '100%',
    width: '100%',
    objectFit: 'cover' as 'cover',
    objectPosition: "top" as "top"
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center' as 'center',
    justifyContent: 'center' as 'center',
    height: '100%',
    backgroundImage: `url(${pexelsCottonbroStudio})`,
    backgroundPosition: 'center' as 'center',
    backgroundSize: 'cover' as 'cover',
    backgroundRepeat: 'no-repeat' as 'no-repeat',
    position: 'relative' as 'relative',
    minHeight: "100vh",
    "@media (max-width:599px)": {
      display: "none"
    },
  },
  rightContainerBackground: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 1,
    minHeight: "100vh",
  },
  heading: {
    marginBottom: '15px'
  },
  textOverImage: {
    position: 'absolute' as 'absolute',
    top: '85px',
    right: '0%',
    transform: 'translate(-50%, -50%)' as 'translate(-50%, -50%)',
    zIndex: 1,
    color: '#fff',
    fontWeight: 'bold' as 'bold',
    fontSize: '20px',
  },
  imageStyle: {
    height: "100%",
    width: "100%",
    marginBottom: 8,
    objectFit: 'contain' as 'contain',
  },
  homecureLogo: {
    width: "200px",
    height: "70px",
    margin: 20,
    objectFit: 'contain' as 'contain',
    "@media (max-width:575)": {
      width: 180,
      margin: 10
    },
  },
  logoTitle: {
    marginBottom: 16,
    fontSize: "20px",
    color: '#fff',
    fontWeight: 'bold' as 'bold',
    textAlign: 'center' as 'center',
  },
  cardContainer: {
    display: 'flex',
    width: '100%',
    margin: 'auto',
    justifyContent: 'center' as 'center',
    flexDirection: 'column' as 'column',
    textAlign: 'center' as 'center',
    paddingTop: '150px',
    "@media (max-width:991px)": {
      paddingTop: '150px'
    },
    "@media (max-width:575)": {
      paddingTop: '100'
    },
  },
  gridHeight: {
    height: "1080px",
    minHeight: "100vh",
    "@media (max-width:660px)": {
      height: "auto"
    },
  },
  selectionButton: {
    color: "#000000",
    backgroundColor: '#F2F6F7',
    border: '1px solid #D9D9D9',
    borderRadius: "8px",
    padding: '10px 20px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    width: '320px',
    height: '60px',
    margin: '10px auto',
    textTransform: 'capitalize' as 'capitalize',
    fontSize: '18px',
    fontFamily: "SF Pro Text Semibold",
    "@media (min-width:600 and max-width:659px)": {
      width: '270px !important',
    },
    "@media (max-width:575px)": {
      width: '70% !important'
    },
  },
  selectedButton: {
    backgroundColor: '#AC8E68',
    color: '#FFFFFF',
    fontWeight: 'bold' as 'bold',
    textTransform: 'capitalize' as 'capitalize',
    fontSize: '18px',
    fontFamily: "SF Pro Text Semibold",
    '&:hover': {
      backgroundColor: '#AC8E68',
      color: '#FFFFFF',
      fontFamily: "SF Pro Text Semibold",
    },
  },
  customButton: {
    backgroundColor: '#014866',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: "8px",
    padding: '10px 20px',
    cursor: 'pointer',
    fontSize: '18px',
    fontFamily: "SF Pro Text Semibold",
    width: '320px',
    height: "60px",
    margin: '10px auto',
    textTransform: 'capitalize' as 'capitalize',
    '&:hover': {
      backgroundColor: '#014866',
      color: '#fff',
    },
    "@media (min-width:600 and max-width:659px)": {
      width: '270px !important',
    },
    "@media (max-width:575px)": {
      width: '70% !important'
    },
  },
  userType: {
    fontFamily: 'SF Pro Text Regular',
    color: "#292929",
    fontSize: "24px",
    textAlign: 'center' as 'center',
    marginVertical: 16,
    caretColor: "transparent",
    "@media (min-width:600 and max-width:659px)": {
      fontSize: "18px",
    },
    "@media (max-width:575px)": {
      fontSize: "24px",
    },
  },
  textBold: {
    caretColor: "transparent",
    fontFamily: 'SF Pro Text Bold !important',
    color: "#292929",
    fontSize: "24px"
  },
  contactUs: {
    top: "35px",
    right: "16px",
    color: "#FFFFFF",
    position: "absolute" as "absolute",
    zIndex: 1,
    fontSize: "16px",
    fontFamily: "SF Pro Text Semibold",
  }
  // Customizable Area End
};

export default withStyles(useStyles)(UserRole);

